body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif; */
    font-family: "Roboto Mono", sans-serif;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f7f6dd;
    /* overflow: hidden; */
}

code {
    /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace; */
    font-family: "Roboto Mono", sans-serif;
}

.six-digit-input {
    font-size: 20px;
    width: 240px;
    box-sizing: border-box;
    height: 40px;
    border: none;
    display: flex;
    padding: 0 5px 0 17px;
    letter-spacing: 30px;
    box-shadow: inset -1px -1px #ffffff, inset 0px 0px #0a0a0a,
        inset -2px -2px #dfdfdf, inset 2px 2px #808080;
    background-color: rgba(255, 255, 255, 0.3);
}

textarea:focus,
input:focus {
    outline: none;
}

.fade-in {
    animation: fadeIn 0.5s;
}

.fade-in-long {
    animation: fadeIn 0.75s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.six-digit-input::placeholder {
    font-size: 17px;
    letter-spacing: 29px;
    font-family: "Roboto Mono", sans-serif;
}
/* 
#phoneNumberInput::placeholder {
    font-size: 16px;
    letter-spacing: 10px;
    font-family: "Roboto Mono", sans-serif;
} */
@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.rotating-bean-dont-ask {
    animation: rotate 1.2s steps(6, end) infinite;
}

@keyframes slideBackBaby {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(50px);
    }
    50% {
        transform: translateX(0);
    }
    75% {
        transform: translateX(-50px);
    }
    100% {
        transform: translateX(0);
    }
}

.sliding-bean-baby-dont-ask {
    animation: slideBackBaby 2s steps(5, end) infinite;
}

#website-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    font-family: "Roboto Mono";
    /* overflow: hidden; */
}

/* .open_slide {
  height: ${({ open }) => (open ? "200px" : "100px")};
  flex-direction: column;
  transition: height 0.3s ease;
} */

@keyframes dropDown {
    from {
        top: -50px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

.drop-down-element {
    position: relative; /* This is needed to use top property in the animation */
    animation: dropDown 0.2s ease-in-out; /* Apply the animation */
}
